<template>
  <div class="CedantRegister" >
    <div class="container-fluid">

      <b-modal size="xl" ref="cedantSaveAccountModal" hide-footer>
        <template v-slot:modal-title>
          <strong> Sauvegarder vos informations et créer votre compte</strong>
        </template>
        <cedantRegistrationSaveAccount
          :hasIdentifyRepreneur="boolHasIdentifyRepreneur"
        />
      </b-modal>

      <h1 class="gavc-h1 text-center">Je cède mon entreprise</h1>
      <div class="row mt-3">
        <div class="col-10 offset-1">
          <stepBar
            :labels="labels"
            :currentStep="Number(cedantRegistrationStep)"
            :totalSteps="labels.length"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="mt-4">
        <cedantRegistrationProject
          v-if="Number(cedantRegistrationStep) === 1"
          v-on:nextStep="nextStep"
          v-on:saveAccount="saveAccount"
        />

        <!--<cedantRegistrationEntreprise
          v-if="Number(cedantRegistrationStep) === 2"
          v-on:nextStep="nextStep"
          v-on:previousStep="previousStep"
          v-on:saveAccount="saveAccount"
        />-->

        <cedantRegistrationRepreneur
          v-if="Number(cedantRegistrationStep) === 2 && boolHasIdentifyRepreneur"
          v-on:nextStep="nextStep"
          v-on:previousStep="previousStep"
        />

        <!--<cedantRegistrationAnnonce
          v-if="Number(cedantRegistrationStep) === 3 && !boolHasIdentifyRepreneur"
          v-on:nextStep="nextStep"
          v-on:previousStep="previousStep"
          v-on:saveAccount="saveAccount"
        />-->

        <cedantRegistrationValidation
          v-if="(Number(cedantRegistrationStep) === 3 && boolHasIdentifyRepreneur) || (Number(cedantRegistrationStep) === 2 && !boolHasIdentifyRepreneur)"
          v-on:nextStep="nextStep"
          v-on:previousStep="previousStep"
        />

      </div>

    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';

const cedantRegistrationProject = () => import('@/components/registration/cedant/cedantRegistrationProject')
//const cedantRegistrationEntreprise = () => import('@/components/registration/cedant/cedantRegistrationEntreprise')
const cedantRegistrationRepreneur = () => import('@/components/registration/cedant/cedantRegistrationRepreneur')
//const cedantRegistrationAnnonce = () => import('@/components/registration/cedant/cedantRegistrationAnnonce')
const cedantRegistrationValidation = () => import('@/components/registration/cedant/cedantRegistrationValidation')
const cedantRegistrationSaveAccount = () => import('@/components/registration/cedant/cedantRegistrationSaveAccount')
const stepBar = () => import('@/components/layout/stepBar')

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});


export default {
  name: 'CedantRegister',
  components: {
    cedantRegistrationProject,
    //cedantRegistrationEntreprise,
    cedantRegistrationRepreneur,
    //cedantRegistrationAnnonce,
    cedantRegistrationValidation,
    cedantRegistrationSaveAccount,
    stepBar
  },
  computed: {
    ...mapFields([
      'cedantRegistrationStep',
      'cedant.hasIdentifyRepreneur',
    ]),
    boolHasIdentifyRepreneur () {
      if (String(this.hasIdentifyRepreneur) === 'true') {
        return true
      }
      return false
    },
    labels () {
      if (String(this.hasIdentifyRepreneur) === 'true') {
        return ['Votre projet de cession​', 'Votre repreneur', 'Validation']
      }
      return ['Votre projet de cession​', 'Validation']
    }
  },

  methods: {
    nextStep () {
      this.cedantRegistrationStep ++
    },
    previousStep () {
      this.cedantRegistrationStep --
    },
    saveAccount () {
      this.$refs.cedantSaveAccountModal.show()
    }

  }
}
</script>
